.containerPagination .prevPagination {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: auto;
  float: left;
  text-align: center;
  cursor: pointer;
  color: #8096ad;
  height:100%;
}

.prevPagination a{
  position: absolute;
  left: 15px;
}

.nextPagination a{
  position: absolute;
  right: 15px;
}

.containerPagination .nextPagination {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  
  cursor: pointer;
  margin-left: auto;
  color: #8096ad;
}

.containerPagination {
  margin-left: auto;
  margin-right: auto;
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  background-color: #012d5a;
  width: 100%;
  height: 5vh;
  display: flex;
}

.pagePagination {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  border-radius: 5px;
  margin: 0px 10px;
  text-align: center;
  color: #a2a2a2;
}

.pagePagination a {
  box-sizing: border-box;
  padding: 15px;
}

.activePagePagination {
  background-color: #f89d1c;
  color: #ffffff;
}
