.tableData {
  font-size: 16px !important;
  line-height: 30px;
}

@media screen and (max-width: 800px) {
  .tableData {
    font-size: 14px !important;
  }
}
