.Navbar {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  height: 90px;
  display: flex;
  vertical-align: top;
  background-color: #0f2748;
  /* clip-path: polygon(0 0%, 100% 0, 65% 100%, 35% 100%); */
  z-index: 11;
}

@media screen and (max-width: 768px) {
  .Navbar {
    height: 80px;
  }
}
