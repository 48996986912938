.rdrCalendarWrapper {
  background-color: #0a1724;
}

.rdrMonthAndYearPickers select {
  color: #f89d1c;
  background: #0f2748;
}

.rdrMonthAndYearPickers select:hover {
  color: #f89d1c;
  background: #172e4e;
}

.rdrNextButton i {
  border-color: transparent transparent transparent #f89d1c;
}

.rdrNextPrevButton {
  background-color: #0b2140;
}

.rdrNextPrevButton:hover {
  background: #172e4e;
}

/* rdrDateDisplayItem rdrDateDisplayItemActive */

.rdrDateInput {
  background-color: #172e4e;
}

.rdrPprevButton i {
  border-color: transparent #f89d1c transparent transparent;
}

.rdrDayNumber span {
  color: #ababab;
}

.rdrDateDisplayWrapper {
  background-color: #0a1724;
}

.rdrDefinedRangesWrapper {
  background-color: #0a1724;
}

@media screen and (max-width: 768px) {
  [class~="rdrStaticRange"]:last-of-type {
    border-right: none;
  }

  .rdrMonths.rdrMonthsHorizontal {
    align-self: center;
  }
  .rdrDateRangePickerWrapper {
    display: flex;
    flex-direction: column-reverse;
  }
  .rdrStaticRanges {
    display: flex;
    flex-direction: row;
  }
  .rdrDefinedRangesWrapper {
    width: 100%;
    border-right: none;
  }
  .rdrStaticRange {
    box-sizing: border-box;
    width: 16.6%;
    height: 30px;
    border-right: 1px solid #172e4e;
    border-bottom: 1px solid #172e4e;
  }
  .rdrStaticRangeLabel {
    text-align: center;
    padding: 0;
  }
}

.rdrStaticRange {
  background-color: #0a1724;
  color: #ababab;
}

.rdrStaticRange .rdrStaticRangeLabel:hover {
  background: #172e4e;
  color: #f89d1c;
}

.rdrStaticRange.rdrStaticRangeSelected .rdrStaticRangeLabel {
  color: #f89d1c;
}

.rdrInRange {
  background-color: #172e4e;
}

.rdrStartEdge {
  background-color: #172e4e;
}

.rdrEndEdge {
  background-color: #172e4e;
}
.rdrInputRanges {
  display: none;
}

.rdrInputRangeInput {
  background-color: #0a1724;
  border: 1px solid #222255;
  color: #d5cea3;
  pointer-events: none;
}

.rdrInputRangeInput:focus {
  background-color: #0a1724;
  border: 1px solid #222255;
  color: wheat;
}
.rdrDayDisabled {
  background-color: #0a1724;
  filter: brightness(80%);
}

.rdrDayDisabled span {
  background-color: #666677;
  border-radius: 45% 0 0 45%;
  width: 90%;
  left: 10%;
}

.rdrDayDisabled span span {
  line-height: normal;
}

.rdrDayDisabled ~ .rdrDayDisabled span {
  left: 0%;
  border-radius: 0;
  width: 100%;
}
