.widget-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.widget {
  position: absolute;
  height: 100%;
  width: 100%;
}

.nomics-ticker-widget-embedded.nomics-ticker-widget-size-responsive.nomics-ticker-widget-bg-light {
  background-color: #446 !important;
  width: 100% !important;
  height: 100% !important;
  max-width: 1800px !important;
}
